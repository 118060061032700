import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import InstarVisionCard from 'components/Software/Windows/InstarVision/Advanced/License/instarVisionCard01';
import NavButtons from 'components/Software/Windows/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for Windows",
  "path": "/Software/Windows/InstarVision/Advanced/License/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "Validate your License Code online or by Email",
  "image": "../../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='InstarVision v3 for Windows' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/InstarVision/Advanced/License/' locationFR='/fr/Software/Windows/InstarVision/Advanced/License/' crumbLabel="License" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0e777c1473a4459d1836ab73bfab3605/29114/Banner_en-InstarVision2-0_Advanced.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAe0lEQVQI143HsQrCMBSF4fsq+nwObordHZrrFHERJ/sqLq4RWnF3cGxSQwzlF1pn6YGPwy/0CaKHGCB18G7HJjN11lrWmwJyRIwLqPPorUPvGW1Gpvlg6vRXWSd2j55iq6yWC0rnETm9kOOT4SuPnH+qqQLz/YXZ4Tr0F9aiwNov85E3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e777c1473a4459d1836ab73bfab3605/e4706/Banner_en-InstarVision2-0_Advanced.avif 230w", "/en/static/0e777c1473a4459d1836ab73bfab3605/d1af7/Banner_en-InstarVision2-0_Advanced.avif 460w", "/en/static/0e777c1473a4459d1836ab73bfab3605/7f308/Banner_en-InstarVision2-0_Advanced.avif 920w", "/en/static/0e777c1473a4459d1836ab73bfab3605/e1c99/Banner_en-InstarVision2-0_Advanced.avif 1380w", "/en/static/0e777c1473a4459d1836ab73bfab3605/76ea5/Banner_en-InstarVision2-0_Advanced.avif 1840w", "/en/static/0e777c1473a4459d1836ab73bfab3605/f696c/Banner_en-InstarVision2-0_Advanced.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0e777c1473a4459d1836ab73bfab3605/a0b58/Banner_en-InstarVision2-0_Advanced.webp 230w", "/en/static/0e777c1473a4459d1836ab73bfab3605/bc10c/Banner_en-InstarVision2-0_Advanced.webp 460w", "/en/static/0e777c1473a4459d1836ab73bfab3605/966d8/Banner_en-InstarVision2-0_Advanced.webp 920w", "/en/static/0e777c1473a4459d1836ab73bfab3605/445df/Banner_en-InstarVision2-0_Advanced.webp 1380w", "/en/static/0e777c1473a4459d1836ab73bfab3605/78de1/Banner_en-InstarVision2-0_Advanced.webp 1840w", "/en/static/0e777c1473a4459d1836ab73bfab3605/882b9/Banner_en-InstarVision2-0_Advanced.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0e777c1473a4459d1836ab73bfab3605/81c8e/Banner_en-InstarVision2-0_Advanced.png 230w", "/en/static/0e777c1473a4459d1836ab73bfab3605/08a84/Banner_en-InstarVision2-0_Advanced.png 460w", "/en/static/0e777c1473a4459d1836ab73bfab3605/c0255/Banner_en-InstarVision2-0_Advanced.png 920w", "/en/static/0e777c1473a4459d1836ab73bfab3605/b1001/Banner_en-InstarVision2-0_Advanced.png 1380w", "/en/static/0e777c1473a4459d1836ab73bfab3605/161ec/Banner_en-InstarVision2-0_Advanced.png 1840w", "/en/static/0e777c1473a4459d1836ab73bfab3605/29114/Banner_en-InstarVision2-0_Advanced.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0e777c1473a4459d1836ab73bfab3605/c0255/Banner_en-InstarVision2-0_Advanced.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "side-navigation---advanced-license",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-navigation---advanced-license",
        "aria-label": "side navigation   advanced license permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side Navigation - Advanced License`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <InstarVisionCard mdxType="InstarVisionCard" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{` Once bought, copy & paste your license code, that you received by Email, into the input field and click on Register Online to unlock your software.`}</p>
    <p>{`Please be aware that your license will be bound to the PC you installed the software on and cannot be transferred to another workstation once validated! In case you want to upgrade your license, simply open the menu, delete the old license and past your new one in the input field - then click on Register Online to validate. Please contact us if you need to reset your license to install the software on another workstation.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f2b55b34f07b06e5410f921aa517eb34/c929c/de_advanced_licence.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAADX0lEQVQozzXRfVPTBQDA8b2RzrtOO6/uqvM8TjsrERZMBvzGNiZjc7CNDcZgjBEOJgwY43GRPHlMCcTEBR1XhylYPqyAgiMgHyIHKpZxF5woOAHZuPt2/dHnLXxESpUWjTYHtVqPVmNEka4nISmDVCEDpTwduVxFfKKAWCKgUKlRazLJ1GSSodERL1GQa7HxYH6ewYEBHi0uIjp7IUjPpTE6zn5Ha8cw/r4pmr+Ypb1vBv/FMYK/zNPV/xOn6gcYGJll/M5jxmdCjE0v8v3EQ4auTRO4HKC1tZUrw8OIpKkqlIocZMp8JGlZHEvW4mq7ypmeW1TVdlFZXYXbW09NQzNVdc14TnuxW0pwu+rw1p3B09hJd08vgYFBAoEAImdjC+bKOnLdDZirfGg9LWQ3XCKv1Et7Sw3+piJqy/LwlBfRWF1BXVkZ1mwzRUY9Dp2Ax6rhXNvn9Ha2cuH8OUSezia83W1UdPrIb/JibfZhsNv5amiIZ2trbG6G2VhfY3V1hc2tbSYnp2lwO+nz2en3GvHX5lM2eIPcwE3OXwsiUhukqI0yThhTyLKosNv0lOQIjN0eJbqzw2b4JcvLyzxdeszu621+Hg9i0qVQaEimQCPGVWrE+zBK9t0I11ejiEaDv3JrYo6puQdMTM3Re7Gfy0NXuf9omSfPXvD0RZiVtefM/vkPSxubfBO8h9jUh1A6QlLxCJrqH2i8/xzLzDqjf238tzyI57NO2nsCBK4EsTiclDhdfHvvbxy/b+O4E+bJq11urkT4cQP8w5PESD8l7mQD8lMj2Lr+wPHbOkkT63y99BLRocMHUajkSJMlqNRKTHkmFOkC1uJi9DYbaUYb+vxSbMWVON3NaPJPc1BykpjEdA4cUyA3uQm/jhDeifJqaxuRMk1AJkiJFx8lJTWB41IxnyTGkqFWIEmI5Z1397Fv/x7efOsN9uzdy9vvvc8HH31IzJGjHDj8MWqdkd1IhN1ohO2tLUSxRw7hKDBTWGBFp8kgLUmMUpByXCJGliagtzox2KvRFlahzjYgl8WhlMWRpVWiVKZSXF7N/6LRKKICqw6HzYjdkolJf4Iisw6DRsBk1lFYmIOv+zr1/Xep/DKEq28el/82loo2yitrKCmroNbXQSgUYmFhgYVQiH8Bs4p5NMpMzcMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f2b55b34f07b06e5410f921aa517eb34/e4706/de_advanced_licence.avif 230w", "/en/static/f2b55b34f07b06e5410f921aa517eb34/d1af7/de_advanced_licence.avif 460w", "/en/static/f2b55b34f07b06e5410f921aa517eb34/7f308/de_advanced_licence.avif 920w", "/en/static/f2b55b34f07b06e5410f921aa517eb34/203bb/de_advanced_licence.avif 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f2b55b34f07b06e5410f921aa517eb34/a0b58/de_advanced_licence.webp 230w", "/en/static/f2b55b34f07b06e5410f921aa517eb34/bc10c/de_advanced_licence.webp 460w", "/en/static/f2b55b34f07b06e5410f921aa517eb34/966d8/de_advanced_licence.webp 920w", "/en/static/f2b55b34f07b06e5410f921aa517eb34/491b1/de_advanced_licence.webp 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f2b55b34f07b06e5410f921aa517eb34/81c8e/de_advanced_licence.png 230w", "/en/static/f2b55b34f07b06e5410f921aa517eb34/08a84/de_advanced_licence.png 460w", "/en/static/f2b55b34f07b06e5410f921aa517eb34/c0255/de_advanced_licence.png 920w", "/en/static/f2b55b34f07b06e5410f921aa517eb34/c929c/de_advanced_licence.png 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f2b55b34f07b06e5410f921aa517eb34/c0255/de_advanced_licence.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If the PC you are working on is not connected to the internet, click on Create ID File and send the file, that is created, by Email to `}<strong parentName="p">{`support at instar  dot de`}</strong>{`. You will receive a reply with the registration file - save the file from the email attachment, click on Register from File and the select it in the following dialogue box.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      